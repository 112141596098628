import ready from "../utils/ready";

class Menu {

  constructor(el) {
    this.el     = el;
    this.toggleButton = el.querySelector(".js-menu-toggle");
    this.popup  = el.querySelector(".menu-popup");
    this.backdrop  = el.querySelector(".menu-backdrop");
    this.handleGlobalClickForPopupBound = this.handleGlobalClickForPopup.bind(this);

    this.popup.hidden = true;
    this.backdrop.hidden = true;

    this.toggleButton.addEventListener("click", (e) => {
      e.preventDefault();
      this.togglePopup();
    });
  }

  togglePopup(force) {

    let oldState = this.toggleButton.getAttribute("aria-expanded") === "true";
    let newState = !oldState;

    if (typeof force !== "undefined") {
      if (force === oldState) {
        return;
      }

      newState = force;
    }

    this.toggleButton.setAttribute("aria-expanded", newState ? "true" : "false");
    this.popup.hidden = !newState;
    this.backdrop.hidden = !newState;

    setTimeout(() => {
      if (newState) {
        window.addEventListener("click", this.handleGlobalClickForPopupBound);
      } else {
        window.removeEventListener("click", this.handleGlobalClickForPopupBound);
      }
    }, 0);
  }

  handleGlobalClickForPopup(e) {
    if (!this.popup.contains(e.target)) {
      e.preventDefault();
      this.togglePopup(false);
    }
  }

}

ready(() => {
  const menu = document.querySelector(".js-menu");
  if (menu) {
    new Menu(menu);
  }
});

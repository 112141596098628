import Flickity from "flickity";
import loaded from "../utils/loaded";

loaded(() => {
  const carousel = document.querySelector(".js-carousel");

  if (!carousel) {
    return;
  }

  new Flickity(carousel, {
    contain: true,
    adaptiveHeight: false,
    imagesLoaded: true,
    wrapAround: true
  });
});

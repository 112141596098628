import ready from "../utils/ready";

ready(() => {
  const links = document.querySelectorAll("a[href*='#']");
  for (let i = 0, l = links.length; i < l; i++) {
    const link = links[i];

    const documentUrl = `${window.location.origin}${window.location.pathname}`;
    const linkTarget = `${link.origin}${link.pathname}`;

    if (documentUrl !== linkTarget) {
      continue;
    }

    link.addEventListener("click", function (e) {
      const targetId = this.hash.replace(/^#/, '');
      const targetEl = document.getElementById(targetId);
      if (targetEl) {
        e.preventDefault();
        targetEl.removeAttribute("id"); // Temporarely Remove id attribute to prevent page scrolling, when updating the hash
        window.location.href = `#${targetId}`;

        targetEl.scrollIntoView({
          behavior: "smooth",
        });

        targetEl.setAttribute("id", targetId);
      }
    });
  }
});

import ready from "../utils/ready";

function tableLabels(table) {

  const labels = [];
  const headers = table.getElementsByTagName("th");

  for (let i = 0, l = headers.length; i < l; i++) {
    labels.push(headers[i].textContent);
  }

  const rows = table.querySelectorAll("tbody tr");

  for (let i = 0, l = rows.length; i < l; i++) {
    const cells = rows[i].getElementsByTagName("td");
    for (let c = 0, cl = cells.length; c < cl; c++) {
      if (labels[c]) {
        cells[c].setAttribute("data-label", labels[c]);
      }
    }
  }
}


ready(() => {

  const tables = document.querySelectorAll(".richtext table");

  for (let i = 0, l = tables.length; i < l; i++) {
    tableLabels(tables[i]);
  }
});

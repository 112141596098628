export default function loaded(fn = function() {}) { /* eslint-disable-line no-empty-function */
  return new Promise((resolve) => {
    if (document.readyState === "complete") {
      fn();
      resolve();
    } else {
      window.addEventListener("load", () => {
        fn();
        resolve();
      });
    }
  });

}

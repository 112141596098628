import ready from '../utils/ready';

// from:https://github.com/jserz/js_piece/blob/master/DOM/ChildNode/remove()/remove().md
(function (arr) {
  arr.forEach(function (item) {
    if (item.hasOwnProperty('remove')) {
      return;
    }
    Object.defineProperty(item, 'remove', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function remove() {
        if (this.parentNode === null) {
          return;
        }
        this.parentNode.removeChild(this);
      }
    });
  });
})([Element.prototype, CharacterData.prototype, DocumentType.prototype]);

class Infobar {

  constructor(el) {
    this.el = el;
    this.closeButton  = el.querySelector('button');
    this.storageKey = 'schlosspraxis_infobar_closed';

    if (!this.hasClosed()) {
      this.el.hidden = false;
    } else {
      this.close();
    }

    this.closeButton.addEventListener('click', (e) => {
      e.preventDefault();
      this.setClosed();
      this.close();
    });
  }

  close() {
    this.el.remove();
  }

  hasClosed() {
    return sessionStorage.getItem(this.storageKey) !== null;
  }

  setClosed() {
    sessionStorage.setItem(this.storageKey, '1');
  }
}

ready(() => {
  const el = document.querySelector('.js-infobar');

  if (el !== null) {
    new Infobar(el);
  }
});
